.check-in-out .bg-text{
    top: 50% !important;
}
.table-workdate .text-center{
    padding: 0 !important;
}
.dark-theme .ant-picker-header button, .dark-theme .ant-picker-content th, 
.dark-theme .ant-picker-cell, .dark-theme .ant-picker-input > input{
    color: inherit;
}
.table-workdate .ant-table-row-expand-icon-spaced{
    display: none;
}