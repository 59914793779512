.gx-inside-header-horizontal:before {
  display: none;
}
.show-filter{
  cursor: pointer;
  position: absolute;
  left: 16px;
  z-index: 2;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.show-filter { color: #fff !important; }
.show-filter .filter{
  font-weight: 400;
  display: inline-block;
  padding: 5px 10px;
  border: #323a43 1px solid;
  border-radius: 6px;
  height: 36px;
  line-height: 24px;
  background-color: #323a43;
}
.show-filter .filter:hover {
  background-color: #293037;
  border-color: #293037;
}
.show-filter .table{
  float: left;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  box-shadow: 0 1rem 1.1rem rgb(0 0 0 / 10%);
}
.show-filter .table .ant-tree{
  padding: 7px;
}
.show-filter table tr td{
  padding: 5px 0;
}
.show-filter table tr td input[type='checkbox'], .icon-trash:hover{
  cursor: pointer;
}
.option-filter, .select-filter .ant-select-selection-search{
  display: none;
}
.select-filter .ant-select-selector{
  padding: 0;
}
.filter-columns .ant-input-group-addon{
  padding: 0;
}
.filter-columns .ant-input-group-addon .ant-select{
  margin: 0;
}
.ant-select-selection-overflow, .ant-select-selection-overflow-item{
  display: inline-block;
}
.header .ant-input-search{
  width: 100%;
}
.header .title {
  font-size: 120%;
  font-weight: bold;
  line-height: 36px;
  text-transform: uppercase;
}
.header .search-filter .ant-select-multiple .ant-select-selection-item {
  /*background-color: rgb(23, 189, 229);*/
  color: #fff;
}
.header .search-filter .ant-select-multiple .ant-select-selection-item-remove {
  color: #fff; 
}

/* .ant-modal, .ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
} */
.custom-footer-modal .ant-modal-footer {height: 100%; padding-top: 16px;}
.custom-body-modal .ant-modal-body {overflow-x: hidden; overflow-y: auto;}
.dfh-modal-body .ant-modal-body {
  height: auto;
}
.full-screen-modal  {width: 100vw !important;max-width: 100vw !important; height: 100vh; padding: 0; top: 0;}
.full-screen-modal .ant-modal-content {height: 100%;}

.font10 {font-size: 10px}
.font12 {font-size: 12px}
.font14 {font-size: 14px}
.font16 {font-size: 16px}
.font18 {font-size: 18px}
.font20 {font-size: 20px}
.font22 {font-size: 22px}
.font24 {font-size: 24px}
.font26 {font-size: 26px}
.font28 {font-size: 28px}
.font30 {font-size: 30px}

.fontWeight700 {font-weight: 700 !important;}
.text-uppercase{text-transform: uppercase!important}

/* .ant-modal-body {
  height: calc(100vh - 110px);
} */
.card-container > .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.modal-confirm, .modal-confirm .ant-modal-content {
  width: 400px !important;
  height: 250px !important;
}
.modal-confirm .ant-modal-body { height: 195px; }
.modal-confirm .ant-modal-body h3, .ant-typography { color: #E0E0E0; }
.ant-modal-body h3{margin: 0;}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger){
  margin-left: 0;
}

.modal-confirm1, .modal-confirm1 .ant-modal-content {
  width: 500px !important;
  height: 170px !important;
}

.popup-confirm .ant-modal-header { padding-top: 15px; padding-bottom: 15px; }
.popup-confirm .ant-modal-body { padding-top: 15px; padding-bottom: 15px; }
.popup-confirm .ant-modal-footer { padding-top: 15px; padding-bottom: 15px; }

.modal-body-none .ant-modal-body{
  display: none;
}
.ant-modal-title{
  width: calc(100% - 25px);
}

.list-order .ant-table-thead th, .tbl-order .ant-table-thead th {
  background: #323a43 !important;
  color: #fff;
  font-weight: 600;
}
/*.list-order .ant-table-row-indent + .ant-table-row-expand-icon {*/
/*  margin-right: 0;*/
/*}*/
.list-order .table-row-dark {
  color: #434f5a;
}
.list-order .ant-table-cell-row-hover {
  background: none !important;
}
.list-order tr:hover > td {
  background: #434f5a !important;
  cursor: pointer;
}
.list-order tr.table-row-dark:hover > td {
  background: #D9D9D9 !important;
}
.table-row-dark {
  background-color: #D9D9D9 !important;
}
thead > tr > th {
  background-color: #fafafa !important;
}
.ant-table tr:nth-child(odd) { background-color: rgba(76, 85, 93); }
.space-w100 > .ant-space-item {width: 100%!important}
.custom-card .ant-card-body {padding: 16px}
.custom-card .ant-card-head {padding-left: 16px; padding-right: 16px}
.wrap-extra-card .ant-card-extra {padding: 0px}

.strike {text-decoration: line-through;}
thead > tr > th {
    background-color: #fafafa !important;
}

.line1 {
  border: 1px solid #545454;
  background-color: #545454;
}

.border1 {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
}

.fontWeight600, .ant-select-selection-item > .fontWeight600 {
  color: #545454 !important;
  font-weight: 600 !important;
}

.order-box .tit-order-box {
  font-size: 120%;
  font-weight: bold;
  line-height: 36px;
}
.order-box .search-oder .ant-input-search{
  width: 100%;
}
.order-box .dark-theme .ant-select-multiple .ant-select-selection-item {
  background-color: rgb(23, 189, 229);
  color: #fff;
}
.order-box .ant-select-multiple .ant-select-selection-item-remove {
  color: #fff; 
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon { background-color: #038fde !important; }
.dark-theme .ant-steps-item-finish .ant-steps-item-icon { background-color: #038fde !important; }
.step-custom .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon { color: #038fde; }
.step-custom .ant-steps-item-finish .ant-steps-item-icon { border-color: #038fde; }

.des-custom .ant-descriptions-item-label { background-color: #434f5a; font-weight: bold;}
.des-custom .ant-descriptions-view { border-radius: 0; }
.des-custom .ant-typography, .des-custom .ant-descriptions-item-label, .des-custom .ant-descriptions-item-content { color: #E0E0E0; }

.des-item-custom {width: 100%; text-align: right}

.enb-scroll-modal .ant-modal-content {
  overflow-y: auto !important;
}

.add-order .ant-modal-content {
  overflow: hidden;
  height: inherit;
}
.add-order .ant-modal-body {
  padding: 0;
}
.add-order .box-left {
  border-right: 1px solid #546471;
  /* //height: calc(100vh - 105px); */
  height: 100vh;
  background: rgba(250, 250, 250, 0.1);
}

.add-order .box-left2 {
  border-right: 1px solid #546471;
  background: rgba(250, 250, 250, 0.1);
}
.add-order .clr {
  clear: both;
}
.add-order .wrap {
  background: #434f5a;
  margin: 0 16px 0 16px;
  padding: 16px 0 16px 0;
}
.add-order .menu-left {
  margin-bottom: 16px;
  border-bottom: 1px solid #546471;
  background: #434f5a;
}
.add-order .menu-left .wrap-left {
  text-align: left;
  width: 60%;
  float: left;
  padding: 16px 5px;
}
.add-order .menu-left .wrap-left2 {
  text-align: left;
  width: 100%;
  float: left;
  padding: 16px 5px;
}
.add-order .menu-left .wrap-right {
  text-align: right;
  width: 40%;
  float: left;
  height: 68px;
  text-transform: uppercase;
  padding-right: 3px;
}
.add-order .menu-left .wrap-right .lblStatus {
  display: inline-block;
  height: 68px;
  padding: 0 5px 0 15px;
  line-height: 68px;
  position: relative;
}
.add-order .menu-left .wrap-right .lblStatus:first-child {
  padding: 0 5px 0 0;
}
.add-order .menu-left .wrap-right .active {
  background: #fa8c15;
}
.add-order .menu-left .wrap-right .txt {
  padding-left: 10px;
}
.add-order .menu-left .wrap-right .txt:last-child {
  padding-left: 25px;
}
.add-order .menu-left .wrap-right .txt::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  position: absolute;
  top: 50%;
  margin-top: -34px;
  left: 100%;
  z-index: 1;
  border-left: 20px solid #434f5a;
}
.add-order .menu-left .wrap-right .txt::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  position: absolute;
  top: 50%;
  margin-top: -34px;
  left: 100%;
  z-index: 2;
  border-left: 20px solid #434f5a;
}
.add-order .menu-left .wrap-right .active .txt::after {
  border-left: 20px solid #fa8c15 !important;
}
.add-order .menu-left .wrap-right .lblStatus:last-child.active .txt::after {
  background: #fa8c15 !important;
}
.add-order .menu-left .wrap-right .lblStatus:last-child .txt::after {
  left: 98% !important;
} 
.add-order .ant-btn {
  margin-bottom: 0;
  border-radius: 6px;
}
.add-order .menu-left .ant-btn:first-child {
  margin-left: 10px;
}
/*.add-order .card-container {
  margin: 0 5px 0 10px;
}*/
.add-order .total .ant-typography {
  line-height: 30px;
}
/*.add-order .ant-row > [class*=ant-col-] { margin: 0 !important; }*/
/* .add-order .tbl-order .ant-table-thead th {
  background: #434f5a !important;
  color: #E0E0E0;
} */
.add-order .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: none;
}
.editable-cell {
  position: relative;
}
.editable-cell-value-wrap {
  padding: 1px 12px;
  cursor: pointer;
  height: 37px;
  line-height: 33px;
  border: 1px solid rgba(250, 250, 250, 0.1);
  border-radius: 6px;
  background: rgba(250, 250, 250, 0.1);
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
}
.add-order .ant-table-tbody > tr > td.ant-table-cell-fix-left {
  background: #4e5c69 !important;
  z-index: 99;
}
.editable-row:hover .editable-cell-value-wrap {
  /*padding: 4px 11px;*/
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px;
  /*min-height: 24px;*/
  z-index: 1;
}
/*[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}*/
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  color: inherit;
  background-color: rgba(250, 250, 250, 0.1) !important;
}

.rm-border-tabs > .ant-tabs-nav::before {border: none}
.custom-list  .ant-list-item {color: #E0E0E0}

.wrap-divider .ant-divider-horizontal.ant-divider-with-text {border-top-color: #55606A}

.custom-tabs > .ant-tabs-nav .ant-tabs-tab { background: none !important; }
.popover-custom {padding-top: 0px !important;}
.popover-custom .ant-table-wrapper{background: #38424B !important;}
.popover-custom .ant-popover-arrow-content::before {background: none}
.popover-custom .ant-popover-arrow{background: none;}
.width400 { width: 400px !important; }
.ant-tabs-tab {background-color: #55606a !important;}

.rm-padding-popover-content .ant-popover-inner-content {padding: 0;}

.ant-popover-arrow  {background: none !important;}
.ant-popover-arrow-content::before {background: #434f5a !important;}
.ant-input-number-input, .list-tags .ant-input {background-color: #55606A !important;}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {background-color: #55606A;}
.ant-table-body {overflow: scroll !important;}

.full-width { width: 100vw !important; max-width: 100vw !important; height: 100vh; margin: 0; top: 0; }
.width-1000 { width: 1000px !important; max-width: 1000px !important; }
.padding-20 { padding: 20px; }
.margin-top-10 { margin-top: 10px; }
.margin-top-15 { margin-top: 15px; }
.margin-top-16 { margin-top: 16px; }
.margin-right-5 { margin-right: 5px; }
.center { text-align: center; }
.group-tag { position: absolute; left: 125px; top: 7px;  }

.w100 { width: 100%; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.hidden { display: none; }
.box { margin: 10px; background-color: #FFF; color: #000; width: 210mm; }
.pdf .infor, .pdf .note {text-transform: uppercase; }
.italic { font-style: italic; }
.bgRed { background: red !important; }
.bgGrey { background: grey !important; }
.bgBlue { background: blue !important; }
.colorRed { color: red; }
.colorWhite { color: white !important; }
.colorGrey { color: grey !important; }
.colorOrange { color: orange; }
.colorGreen { color: green; }
.ml-0 { margin-left: 0px !important; }
.ml-8 { margin-left: 8px; }
.ml-16 { margin-left: 16px; }
.mr-16 { margin-right: 16px; }
.mr-20 { margin-right: 20px; }
.mr-0 { margin-right: 0px !important; }
.mr-10 { margin-right: 10px !important; }
.mt-16 { margin-top: 16px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-16 { margin-bottom: 16px !important; }
.pb-5 { padding-bottom: 5px !important; }

.pdf .title, .pdf .infor-cus, .pdf .footer { padding: 5px; }
.pdf .logo { width: 50%; display: inline-block; vertical-align: top; }
.pdf .address { width: 50%; padding-top: 15px; padding-right: 15px; display: inline-block; }
.pdf .infor-cus .infor-left { width: 45%; padding-left: 30px; display: inline-block; }
.pdf .infor-cus .infor-right { width: 55%; padding-left: 30px; display: inline-block; }
.pdf .note { padding: 0 20px; margin-bottom: 20px; } 
.pdf table th { background: red !important; }
.pdf table th, .pdf table td { border: 1px solid #000; padding: 4px 8px; }
.pdf .footer { margin-top: 20px; }
.pdf p { margin-bottom: 5px; }

/*.table-row-red { background-color: red; }
.table-row-green { background-color: green; }*/

.table-row-white td:nth-child(1), .table-row-white td:nth-child(2), .table-row-white td:nth-child(4) { position: relative; }
.table-row-white td:nth-child(1) .triangle-top-left, 
.table-row-white td:nth-child(2) .triangle-top-left{
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.table-row-white td:nth-child(3) .triangle-top-right,
.table-row-white td:nth-child(4) .triangle-top-right{
  width: 0;
  height: 0;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
}

.table-row-blue td:nth-child(1), .table-row-blue td:nth-child(2), .table-row-blue td:nth-child(4) { position: relative; }
.table-row-blue td:nth-child(1) .triangle-top-left, 
.table-row-blue td:nth-child(2) .triangle-top-left,
.table-row-blue td:nth-child(4) .triangle-top-left {
  width: 0;
  height: 0;
  border-top: 10px solid #2196f3;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.table-row-green td:nth-child(1) { position: relative; }
.table-row-green td:nth-child(1) .triangle-top-left {
  width: 0;
  height: 0;
  border-top: 10px solid #87d068;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.table-row-yellow td:nth-child(1), .table-row-yellow td:nth-child(2), .table-row-yellow td:nth-child(3) { position: relative; }
.table-row-yellow td:nth-child(1) .triangle-top-left,
.table-row-yellow td:nth-child(2) .triangle-top-left,
.table-row-yellow td:nth-child(3) .triangle-top-left,
.table-row-yellow td:nth-child(4) .triangle-top-left{
  width: 0;
  height: 0;
  border-top: 10px solid orange;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.no-border { border: none !important; }
.no-bg { background: none !important; }

.list-order table .ant-tag { margin-bottom: 0; }
.card-lh .ant-card-bordered { border: 1px solid #ededed !important; }
.card-lh .ant-card-body h4, .card-lh .ant-card-actions > li > span > .anticon { color: #ededed !important; }
.card-lh .ant-divider { background: #ededed !important; }
.card-lh .ant-card-actions { border-top-color: #ededed !important; }
.card-lh .ant-card-actions > li:not(:last-child) { border-right-color: #ededed !important; }

/*.tbl-order .ant-input:hover {*/
/*  border: none !important;*/
/*}*/
.tbl-order .ant-table .ant-table-body tr { height: 36px; }
.tbl-order .ant-table .ant-table-body tr td { padding: 0 !important; }
.tbl-order .ant-input { line-height: 33px; padding: 1px 11px; }

.dark-theme .ant-btn { border-radius: 6px; }
.dark-theme .ant-table-small { background-color: #4e5c69; }
.gx-main-content-wrapper { padding: 16px 16px 0 !important; }
.dark-theme .ant-picker-content th { background: #323a43 !important; color: #fff;  }
.dark-theme .ant-picker-content th:first-child { border-radius: 6px 0 0 6px; }
.dark-theme .ant-picker-content th:last-child { border-radius: 0 6px 6px 0; }
/*.dark-theme .ant-input-affix-wrapper,*/ .dark-theme .ant-input-group-addon { border-color: rgba(250, 250, 250, 0.1) !important; }
.dark-theme .ant-input-affix-wrapper { padding: 0 0 0 11px; line-height: 2.4; }
/*.dark-theme .ant-input:hover { border: none !important; }*/
.dark-theme .ant-table-small table { border-top-color: rgba(250, 250, 250, 0.1) !important; }
.dark-theme .ant-table-bordered .ant-table-thead > tr > th, 
.dark-theme .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after { border-right-color: #5a6c79 !important; }

.inputSearch input { text-transform: uppercase; }
.list-order .ant-table-cell-scrollbar:not([rowspan]), .tbl-order .ant-table-cell-scrollbar:not([rowspan]) { box-shadow: none; }
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, 
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover { background: none !important; }
/*.ant-select-single.ant-select-show-arrow .ant-select-selection-item { font-weight: bold; }*/
/*.ant-select-single.ant-select-open .ant-select-selection-item, .tbl-order .ant-select { color: orange; }*/
.text-center .ant-input-number-input { text-align: center !important; }
.text-right .ant-input-number-input { text-align: right !important; }
.add-order .ant-modal-header { padding: 12px 18px; }
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-focused { border-color: #28aaeb !important; }
.tbl-order .ant-table-row-indent + .ant-table-row-expand-icon { margin-top: 0; margin-right: 0; margin-bottom: 3px; }
.ant-table-row-expand-icon { float: none; }
.ant-select-focused:hover, .col-category-select .ant-select:hover, .col-category-select .ant-select-focused:hover,
.col-category-group .ant-select:hover, .col-category-group .ant-select-focused:hover,
.order-select-product:hover .ant-select-selector, .editable-row:hover .ant-select-selector,
.ant-select-auto-complete input:hover, .tbl-order .ant-table-row:hover .ant-input-number,
.tbl-order .ant-table-row:hover .ant-select-selector input, .tbl-order .ant-table-row:hover .ant-input { border-color: #d9d9d9 !important; }
.close-log-box { position: absolute; top: 40vh; }
.orange { color: orange; }
.green { color: #87d068}
.blue { color: #2196f3}
.order-select-category input { font-weight: bold !important; }
.inputSearch .ant-input-search-button:not(.ant-btn-primary) { color: #e0e0e0 !important; }
.inputSearch .ant-btn { background-color: #323a43 !important; }
.tbl-order .ant-select-selection-item, .name_customer input { text-transform: uppercase; }
.dark-theme .ant-select-dropdown { width: 500px !important; }
@media screen and (max-width: 575px) {
  .dark-theme .ant-select-dropdown { width: 300px !important; }
}
.add-order ::-webkit-scrollbar, .list-order ::-webkit-scrollbar {
  display: block;
  height: 30px; 
}
.wrap_time { min-width: 1500px; }
.list-order .ant-checkbox-disabled .ant-checkbox-inner { display: none; }
.list-work-detail .ant-table-row-expand-icon-expanded + span { vertical-align: bottom; color: orange; }
.border-bottom { border-bottom: 1px solid #495762; }
.border-bottom-detail { border-bottom: 1px solid #495762; display: block; padding-bottom: 12px; }
.border-left { border-left: 1px dashed #495762; }
.ant-popover { max-width: 250px; }
.ant-popover-title { color: #fff !important; font-weight: bold; }
.error-empty { border: 1px solid #f00; }
.btn-custom { margin: 0 !important; padding: 0 5px; border: none; vertical-align: top; }
.ml-40 { margin-left: 40px !important; }
.line { border-bottom: 1px solid #546471; margin-bottom: 15px !important; }
.no-margin { margin: 0 !important; }

/*dot*/
.ant-badge-status-dot{width: 16px; height: 16px;}
.dot-green>span {background-color: green}
.dot-grey>span {background-color: gray}
.dot-brown>span {background-color: brown}
.dot-orange>span {background-color: orange}
.dot-red>span {background-color: #ff0000}
.dot-purple>span {background-color: purple}
.dot-bright-blue>span {background-color: #484bfc}
.dot-yellow>span {background-color: #bdac04}
.dot-pure-red>span {background-color: #ff0d00}
.dot-orange-dark-text>span {background-color: #ABA895}
.dot-light-green>span {background-color: #87d068}
.dot-dark-blue-sec>span {background-color: #17BDE5}
.dot-pure-blue>span {background-color: #0073ff}

.history-import tbody tr { cursor: pointer; }
.cashier-customer input { text-transform: uppercase; }

.box-calendar { width: 770px; margin: 0 auto; }
.box-calendar .ant-row { margin: 0 !important; }
.box-calendar .ant-row .box-date:first-child { border-left: 1px solid #e0e0e0; }
.box-date { width: 110px; height: 110px; padding: 5px; border-right: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0; background: #4e5c69; }
.box-date:hover { background: #434f5a; }
.box-header { height: 30px !important; border-top: 1px solid #e0e0e0; font-weight: bold; background: #323a43; }

@media screen and (max-width: 575px) {
  .box-wrap { overflow-x: scroll; width: 100%; }
}

.report-quote-type .ant-radio-wrapper { padding-bottom: 10px; }
.sale_request .col-category-group .ant-select-selector .ant-select-selection-item { color: orange; font-weight: bold; }
.branch-list .ant-drawer-title { color: #fff; text-transform: uppercase; }
.branch-list .ant-drawer-content-wrapper { width: 200px !important; }
.branch-list .ant-drawer-body { margin-top: 16px; }

.ant-tree-treenode, .ant-tree-node-content, .ant-tree-node-content-wrapper{
    width: 100%;
}
.ant-tree-treenode button{
    margin: 0px;
}
.ant-tree-switcher-noop{
    display: none;
}
.product-detail-modal{
    width: 100% !important;
}

.have-btn-add .ant-form-item-control{
    padding-right: 50px;
}
.have-btn-add .ant-btn-circle{
    margin: 0px;
    position: absolute;
    top: 5px; 
    right: 5px;
}

.add-units .ant-btn-circle{
    margin: 5px 0 0 5px;
    float: left;
}

.add-units .ant-badge{
    cursor: pointer;
}

.add-units .ant-badge .ant-badge-count{
    font-size: 18px;
}

.modal-select{
    float: right;
}

.modal-select .ant-modal-content{
    width: 100% !important;
}

.modal-select .ant-modal-body{
    padding: 0;
}

.modal-select .ant-modal-body .ant-row{
    margin: 0;
}

.modal-select .ant-modal-body .ant-col{
    padding: 0 !important;
}

.collapse-items .ant-form-item-label{
    text-align: left;
}

.product-image .ant-upload-select-picture-card{
    margin-bottom: 0;
}

.infor-staff .ant-upload-select-picture-card{
  margin-bottom: 0;
}

.product-image .gx-text-center{
    text-align: left !important;
}

.search-filter .gx-text-uppercase input{
    text-transform: uppercase;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content { left: calc(50% - 12px); }
.infor-staff .gx-app-login-content { width: 100% !important; }
.gx-size-20 { width: 20px !important; height: 20px !important; }
.gx-header-horizontal-main, .ant-layout-header, .gx-header-horizontal-main-flex { height: 45px !important; }
.ant-layout-header .ant-menu-horizontal { line-height: 45px; height: 45px; }

@media screen and (max-width: 575px) {
  .gx-main-content-wrapper {
    padding: 15px 15px 0 !important;
  }
  
  .bg-text {
    top: 1800% !important;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
}

.btn-green { background: #87d068 !important; }
.btn-blue { background: #2196f3 !important; }

.tracklog { color: #fff; }
.tracklog .gx-timeline-panel { padding: 20px; }
.tracklog .gx-timeline-heading, .tracklog .gx-timeline-panel-header { margin-bottom: 0; }
.wrap-divider > div:last-child > div { margin-bottom: 0 !important; }

.childOption { padding-left: 30px; }

.drawer-log .ant-drawer-header, .drawer-comment .ant-drawer-header { display: none !important; }
.drawer-comment .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #38424b;
}
.drawer-comment .ant-drawer-content { background: #38424b; }

.comments-title {
  font-size: 30px;
  margin-bottom: 20px;
}

.comments-container {
  margin-top: 40px;
}

.comment-form-title {
  font-size: 22px;
}

.comment-form-textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 7.9px 11px;
  border: 1px solid #d9d9d9;
  color: #e0e0e0;
  background-color: #55606a;
  border-radius: 6px;
}

.comment-form-button {
  font-size: 16px;
  padding: 8px 16px;
  background: rgb(59, 130, 246);
  border-radius: 8px;
  color: white;
}

.comment-form-button:hover:enabled {
  cursor: pointer;
  background: rgb(37, 99, 235);
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-form-cancel-button {
  margin-left: 10px;
}

.comment {
  display: flex;
  margin-bottom: 28px;
}

.comment-image-container {
  margin-right: 12px;
}

.comment-image-container img {
  border-radius: 50px;
}

.comment-right-part {
  width: 100%;
}

.comment-content {
  display: flex;
}

.comment-author {
  margin-right: 8px;
  color: rgb(59, 130, 246);
}

.comment-text {
  /*font-size: 18px;*/
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin-top: 8px;
}

.comment-action {
  margin-right: 8px;
}

.comment-action:hover {
  text-decoration: underline;
}

.replies {
  margin-top: 20px;
}

#orderDetail { height: calc(100vh - 50px); overflow: auto; }
.tbl-order .table-row-dark, .tbl-order .table-row-dark .ant-input, .tbl-order .table-row-dark .ant-select { color: #434f5a; }
.tbl-order .table-row-dark:hover .ant-input, .tbl-order .table-row-dark:hover .ant-select, .tbl-order .table-row-dark:hover, 
.tbl-order .table-row-dark .ant-input:hover, .tbl-order .table-row-dark .ant-select:hover { color: #e0e0e0 !important; }

.detail-image { text-align: center; }
.detail-description { white-space: pre-wrap; }
.detail-description hr { margin: 0 !important; }

.product-stock-detail .ant-card-body { padding: 0 !important; }
.searchCardTrello input { text-transform: uppercase !important; }

.hiddenBox { visibility: hidden; height: 0; }
.ant-upload-list-picture-card-container, .ant-upload.ant-upload-select-picture-card { width: 160px !important; height: 160px !important; }
.ant-form-horizontal .wrap .ant-form-item-label[class*='-24 '] + .ant-form-item-control { min-width: 0; }

.red-type-customer { color: rgb(234, 125, 125); }
.green-type-customer { color: #13c2c2; }
#divApproveQuotes { overflow-y: auto; height: calc(100vh - 150px); }
.detail-quote-mobile .ant-modal-title, .detail-quote-mobile .ant-modal-body, .detail-quote-mobile .ant-table.ant-table-small { font-size: 12px !important; }
.type-label { color: #fff; padding: 1px 7px 2px 7px; border-radius: 4px; margin-right: 7px; }
.fixHeight { overflow-y: auto; height: calc(100vh - 130px); }
.fixHeight2 { overflow-y: auto; height: calc(100vh - 165px); }